import React, { useEffect, useState } from "react";
import { useLocation, Link } from "react-router-dom";
 // Create and import a CSS file for styling

const SearchResults = () => {
  const location = useLocation();
  const { searchType, searchText } = location.state;
  const [searchimg, setSearchimg] = useState([]);
  const [imgPath, setImgPath] = useState("");

  useEffect(() => {
    searchFunction(searchType, searchText);
  }, [searchType, searchText]);

  const searchFunction = (type, text) => {
    const apiUrl = "https://photography.ehtwebaid.com/backend/public/api/searchImage";

    fetch(apiUrl, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ categoryname: text, imagetype: type }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status) {
          setSearchimg(data.data);
          setImgPath(data.path);
        } else {
          console.log("No data or empty array received from the API.");
        }
      })
      .catch((error) => console.log(error));
  };

  return (
    <div className="search-result">
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="ra-search-main-item">
              {searchimg.map((img) => (
                <Link key={img.id} to={`/MainImage/${img.id}`} className="search-result-item">
                  <img src={`${imgPath}${img.image}`} alt={img.imageType} />
                  {/* <p>{img.imageType}</p> */}
                </Link>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchResults;
