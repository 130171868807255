// import logo from './logo.svg';
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Galary from "./component/galary";
import InnerGalary from "./component/innergalary";
import MainImage from "./component/MainImage";
import Home from "./component/home";
import About from "./component/About";
import SocialMedia from "./component/socialMedia";
import BookNow from "./component/bookNow";
import Contact from "./component/contact";
import Navber from "./navber";
import Loginsignup from "./component/login&signup";
import Searchresult from "./component/searchresult";
import AddTofev from "./component/addTofev";
import PaymentForm from "./component/payment";
import Resetpassword from "./component/resetpassword";
import OrderHistory from "./component/orderHistory";

import AddTocart from "./component/addTocart";
import Footer from "./footer";

function App() {
  return (
    <>
      
      
      {/* <Galary /> */}
      <Router>
        <Navber />
        <Routes>
        <Route path="/" element={<Home />} />
          <Route path="/InnerGalary/:id" element={<InnerGalary />} />
          <Route path="/Galary" element={<Galary  />} />
          <Route path="/MainImage/:id" element={<MainImage />} />
          <Route path="/About" element={<About />} />
          <Route path="/SocialMedia" element={<SocialMedia />} />
          <Route path="/BookNow" element={<BookNow />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/Loginsignup" element={<Loginsignup/>} />
          <Route path="/AddTocart" element={<AddTocart/>} />
          <Route path="/Searchresult" element={<Searchresult/>} />
          <Route path="/AddTofev" element={<AddTofev/>} />
          <Route path="/PaymentForm" element={<PaymentForm/>} />
          <Route path="/Resetpassword" element={<Resetpassword/>} />
          <Route path="/OrderHistory" element={<OrderHistory/>} />
     
        </Routes>
        <Footer />
      </Router>
    </>
  );
}

export default App;
