import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2"; // Import SweetAlert2
import "./Loginsignup.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

const Resetpassword = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const handlePasswordToggle = () => {
    setShowPassword(!showPassword);
  };

  const validateEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = {};

    if (!validateEmail(email)) {
      errors.email = "Please enter a valid email address";
    }

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }

    try {
      const response = await fetch(
        "https://photography.ehtwebaid.com/backend/public/api/resetPassword",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email, password }),
        }
      );

      const data = await response.json();

      if (data.status) {
        Swal.fire({
          title: "Success!",
          text: data.message,
          icon: "success",
          confirmButtonText: "OK",
        }).then(() => {
          navigate("/Loginsignup"); // Redirect to home page after successful password reset
        });
      } else {
        setErrors({ api: data.message });
      }
    } catch (error) {
      setErrors({ api: "An error occurred. Please try again later." });
    }
  };

  return (
    <div
      className="main-login-signup-img"
      style={{
        backgroundImage: "url(/img/login.png)",
        objectFit: "cover",
        width: "100%",
        height: "90vh",
        backgroundSize: "cover",
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="ra-resetlogin">
        <div className="wrapper">
          <div className="slide-controls">
            <input type="radio" name="slide" id="login" checked readOnly />
            <label htmlFor="login" className="slide ra-login">
              Forgot password
            </label>
            <div className="slider-tab"></div>
          </div>
          <div className="form-container">
            <div className="form-inner">
              <form onSubmit={handleSubmit} className="login">
                <div className="field">
                  <label htmlFor="email">Email</label>
                  <div className="input-login-icon-wrapper">
                    <i className="fa-solid fa-envelope"></i>
                    <input
                      type="email"
                      id="email"
                      placeholder="Your Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                    {errors.email && <p className="error">{errors.email}</p>}
                  </div>
                </div>
                <div className="field">
                  <label htmlFor="password">New Password</label>
                  <div className="input-sign-icon-wrapper">
                    <input
                      type={showPassword ? "text" : "password"}
                      id="password"
                      placeholder="New Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                    />
                    <i
                      className={`fa-solid ${
                        showPassword ?  "fa-eye" : "fa-eye-slash"
                      }`}
                      onClick={handlePasswordToggle}
                    ></i>
                  </div>
                </div>
                {errors.api && <p className="error">{errors.api}</p>}
                <div className="ra-login-btn">
                  <input type="submit" value="Reset Password" />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Resetpassword;
