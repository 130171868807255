import { useState, useEffect } from "react";

function SocialMedia() {



  const [socialM, setSocialM] = useState([]);
  const [imgpath, setImgpath] = useState("");

  useEffect(() => {
    fetch("https://photography.ehtwebaid.com/backend/public/api/socialmediadetails")
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (data.status) {
          setSocialM(data.data);
          setImgpath(data.path);
        } else {
          console.log("No data or empty array received from the API.");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);


  return (
    <div>
      <div
        className="ra-main-social"
        style={{ backgroundImage: "url(/img/socialimg.png)" }}
      >
        <div className="container">
          <div className="ra-social-media">
            <div className="row flex-row-reverse">
            <div className="col-md-5">
                <div className="ra-social-media-img">
                  <a href="">
                  <img src={`${imgpath}/${socialM.image}`} alt="" />
                  </a>
                </div>
              </div>
              <div className="col-md-7">
                <div className="ra-social-media-text">
                  <h1>{socialM.title}</h1>
                  <p>
                    {socialM.description}
                  </p>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="ra-icon1">
                        <i class="fa-brands fa-facebook">
                          <h2>LOREM IPSUM</h2>
                        </i></div>


                        <div className="ra-icon4">
                        <i class="fa-brands fa-instagram">
                          <h2>LOREM IPSUM</h2>
                        </i>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="ra-icon2">
                        <i class="fa-brands fa-linkedin-in">
                          <h2>LOREM IPSUM</h2>
                        </i>
                        </div>
                        <div className="ra-icon3">
                        <i class="fa-brands fa-x-twitter">
                          <h2>LOREM IPSUM</h2>
                        </i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SocialMedia;
