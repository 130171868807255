import React, { useState, useEffect } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import "./galary.css";

function Innergalary() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [Category, setCategory] = useState({});
  const [images, setImages] = useState([]);
  const [imgPath, setImgPath] = useState("");

  useEffect(() => {
    fetchCategoryDetails(id);
  }, [id]);

  const fetchCategoryDetails = (categoryId) => {
    const apiUrl = "https://photography.ehtwebaid.com/backend/public/api/categorydetails";

    fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ categoryId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status) {
          setCategory(data.data.category);
          setImages(data.data.images);
          setImgPath(data.path);
        } else {
          console.log("No data or empty array received from the API.");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchNextGallery = () => {
    const apiUrl = "https://photography.ehtwebaid.com/backend/public/api/nextGallery";

    fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ categoryId: id }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status) {
          const nextCategoryId = data.data.category.id;
          navigate(`/Innergalary/${nextCategoryId}`);
          setCategory(data.data.category);
          setImages(data.data.images);
          setImgPath(data.path);
        } else {
          console.log("No data or empty array received from the API.");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      {/* Banner Section */}
      <div className="banner">
        {Category.categoryImage && (
          <img src={`${imgPath}/${Category.categoryImage}`} alt="" />
        )}
        <div className="overlay">
          <div className="container">
            <h2>{Category.categoryName}</h2>
            <div className="ra-about-btn">
              <button onClick={fetchNextGallery} className="ra-next-btn">
                Next Gallery
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Gallery Section */}
      <div className="container">
        <div className="ra-galarypart">
          <div className="row">
            {images.map((image) => (
              <div key={image.id} className="ra-column">
                <Link to={`/MainImage/${image.id}`}>
                  <img
                    src={`${imgPath}/${image.image}`}
                    className="mb-1 img-fluid"
                    alt={`Gallery Image ${image.id}`}
                  />
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Innergalary;
