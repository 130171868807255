import React from "react";

function About() {
  return (
    <div>
      <div className="ra-main-aboutme">
        <div className="container">
          <div className="ra-about-Lauren-Smith">
            <div className="row">
              <div className="col-md-6">
                <div className="ra-main-aboutme-text">
                  <div className="ra-aboutme-text1">
                    <h1>Lauren Smith</h1>
                    <h5>PHOTOGRAPHER</h5>
                    <p>
                      Lorem ipsum dosectetur adipisicing elit, sed do.Lorem
                      ipsum dolor sit amet, consectetur Nulla fringilla purus at
                      leo dignissim congue. Mauris elementum accumsan leo vel
                      tempor. Sit amet cursus nisl aliquam. Aliquam et elit eu
                      nunc rhoncus viverra quis at felis. Sed do.Lorem ipsum
                      dolor sit amet.
                    </p>
                  </div>

                  <div className="ra-aboutme-text2">
                    <h3>
                      “It takes a lot of imagination to be a good photographer.
                      Everything is so ordinary..”
                    </h3>
                    <p>
                      Meh synth Schlitz, tempor duis single-origin coffee ea
                      next level ethnic fingerstache fanny pack nostrud. Photo
                      booth anim 8-bit hella, PBR 3 wolf moon beard Helvetica.
                      Salvia esse nihil, flexitarian Truffaut synth art party
                      deep v chillwave. Seitan High Life reprehenderit
                      consectetur cupidatat kogi. Et leggings fanny pack.
                    </p>
                    <p>
                      See-through delicate embroidered organza blue lining
                      luxury acetate-mix stretch pleat detailing. Leather detail
                      shoulder contrastic colour contour stunning.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="ra-aboutme-img">
                  <img
                    src="img/aboutme1.jpg"
                    alt=""
                    srcset=""
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="ra-about-LOREM-IPSUM">
            <div className="row">
              <div className="col-md-6">
                <div className="ra-aboutme-LOREM-img">
                  <img
                    src="img/aboutme2.jpg"
                    alt=""
                    srcset=""
                    className="img-fluid"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="ra-main-LOREM-aboutme-text">
                  <div className="ra-aboutme-LOREM-text2">
                    <h3>
                      “Growing up in a hippie family that had an emphasis on
                      relationships, combined with the experience of working
                      with a fine craftsman at an early age, have led me to a
                      specific vision for this artist collective.”
                    </h3>
                    <h5>LOREM IPSUM</h5>
                    <p>On her inspiration</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
