import { createSlice, createAsyncThunk , useEffect} from "@reduxjs/toolkit";
import axios from "axios";

export const loginUser = createAsyncThunk(
  "user/loginUser",
  async (userData, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        "https://photography.ehtwebaid.com/backend/public/api/loginuser",
        userData
      );
      
      const { data } = response.data;
      console.log(data);
      let json = JSON.stringify({ userid: data.id, name: data.name, email: data.email });
      localStorage.setItem("user", json);

      // Merge local storage cart with server-side cart
      // const localCart = JSON.parse(localStorage.getItem("cart")) || [];
      // if (localCart.length > 0) {
      //   await axios.post("https://photography.ehtwebaid.com/backend/public/api/mergeCart", {
      //     customerid: data.id,
      //     cartItems: localCart,
      //   });
      // }



      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


export const registerUser = createAsyncThunk(
  "user/registerUser",
  async (userData, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        "https://photography.ehtwebaid.com/backend/public/api/register",
        userData
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const userSlice = createSlice({
  name: "user",
  initialState: {
    user: null,
    loading: false,
    error: null,
  },
  reducers: {
    logout(state) {
      state.user = null;
      localStorage.removeItem("user");
      // Optionally clear the cart from local storage on logout
      localStorage.removeItem("cart");
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(registerUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(registerUser.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
      })
      .addCase(registerUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { logout } = userSlice.actions;

export default userSlice.reducer;
