import { useState, useEffect } from "react";
import "./galary.css";

function Maingalary() {
  const [Galaryc, setGalaryc] = useState([]);
  const [imgpath, setImgpath] = useState("");

  useEffect(() => {
    fetch("https://photography.ehtwebaid.com/backend/public/api/categoryfetch")
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (data.status) {
          setGalaryc(data.data);
          setImgpath(data.path);
        } else {
          console.log("No data or empty array received from the API.");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div>
      {/* Gallery Section */}
      <section>
        {Galaryc.map((item, index) => (
          // <div key={item.id}>
           
              <div key={item.id} className={index % 2 === 0 ? 'showcase' : 'ra-showcase'}>
          
            
              <img src={`${imgpath}/${item.categoryImage}`} alt="" />
              <div className="overlay">
                <div className="container">
                  <div className="row">
                    <div className="col-md-12">
                    <div className={`ra-galary-text ${index} % 2 === 0 ? 'text-left' : 'text-right' `}>
                    
                    <div  className="ra-sub-galary-text">
                            <h2>{item.categoryName}</h2>
                            <div className="ra-about-btn">
                              <a
                                href={`/Innergalary/${item.id}`}
                                className="default-btn"
                              >
                                View Gallery
                              </a>
                        
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          // </div>
        ))}
      </section>
    </div>
  );
}

export default Maingalary;
