import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import axios from 'axios';
import Swal from 'sweetalert2';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./galary.css";

const MainImage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [mainimg, setMainimg] = useState({});
  const [price, setPrice] = useState([]);
  const [relatedImage, setRelatedImage] = useState([]);
  const [imgPath, setImgPath] = useState("");
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedPrice, setSelectedPrice] = useState(null);
  let user = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    const apiUrl = "https://photography.ehtwebaid.com/backend/public/api/categoryimagedetails";

    fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ imageId: id }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status) {
          setMainimg(data.data.image);
          setRelatedImage(data.data.relatedImage);
          setImgPath(data.path);
        } else {
          console.log("No data or empty array received from the API.");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id]);

  useEffect(() => {
    fetch("https://photography.ehtwebaid.com/backend/public/api/pricedetails")
      .then((response) => response.json())
      .then((data) => {
        if (data.status) {
          setPrice(data.data);
        } else {
          console.log("No data or empty array received from the API.");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleRowClick = (index, price) => {
    setSelectedRow(index);
    setSelectedPrice(price);
  };

  const handleAddToCart = async () => {
    if (!selectedPrice) {
      Swal.fire({
        icon: 'warning',
        title: 'Oops...',
        text: 'Please select a price option.',
      });
      return;
    }
  
    if (!user) {
      navigate('/Loginsignup'); // Redirect to login page if not logged in
      return;
    }
  
    try {
      const response = await axios.post('https://photography.ehtwebaid.com/backend/public/api/addtocart', {
        customerid: user.userid,
        imageid: id,
        priceid: selectedPrice.id,
        quantity: 1,
        totalprice: selectedPrice.price,
      });
  
      if (response.data.message === "This Item Already Exist !!!!!") {
        Swal.fire({
          icon: 'warning',
          title: 'Item Already Exists',
          text: 'This item is already in your cart.',
        }).then(() => {
          navigate('/AddTocart'); // Navigate to Add to Cart page
        });
      } else {
        Swal.fire({
          icon: 'success',
          title: 'Added to Cart',
          text: 'Your item has been successfully added to the cart.',
        });
        navigate('/AddTocart'); // Navigate to Add to Cart page
      }
    } catch (error) {
      console.error("Error adding to cart:", error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'There was an issue adding the item to the cart. Please try again.',
      });
    }
  };

  const handleAddToFavourite = async () => {
    if (!selectedPrice) {
      Swal.fire({
        icon: 'warning',
        title: 'Oops...',
        text: 'Please select a price option.',
      });
      return;
    }

    if (!user) {
      navigate('/Loginsignup'); // Redirect to login page if not logged in
      return;
    }

    try {
      const response = await axios.post('https://photography.ehtwebaid.com/backend/public/api/addfavourite', {
        customerid: user.userid,
        imageid: id,
        priceid: selectedPrice.id,
      });

      Swal.fire({
        icon: 'success',
        title: 'Added to Favourite',
        text: 'Your item has been successfully added to the favourite list.',
      });

      console.log(response.data);
    } catch (error) {
      console.error("Error adding to favourite:", error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'There was an issue adding the item to the favourite list. Please try again.',
      });
    }
  };

  const handleRelatedImageClick = (imageId) => {
    navigate(`/mainimage/${imageId}`);
  };

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
  };

  return (
    <div>
      <div className="main-image">
        {mainimg.image && (
          <img src={`${imgPath}/${mainimg.image}`} alt="Main Image" />
        )}
      </div>
      <div className="container">
        <div className="image-details">
          <div className="row">
            <div className="col-md-8">
              <div className="part1">
                <table>
                  <thead>
                    <tr>
                      <th>Image Type</th>
                      <th>Resolution</th>
                      <th>Dimensions (px)</th>
                      <th>File Format</th>
                      <th>Price</th>
                    </tr>
                  </thead>
                  <tbody>
                    {price.map((item, index) => (
                      <tr
                        key={item.id}
                        className={selectedRow === index ? 'selected' : ''}
                        onClick={() => handleRowClick(index, item)}
                      >
                        <td>{item.imageType}</td>
                        <td>{item.resolution}</td>
                        <td>{item.dimension}</td>
                        <td>{item.fileFormat}</td>
                        <td>${item.price}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="col-md-4">
              <div className="ra-cart-section">
                <div className="ra-cart-btn">
                  <button className="Addcart-btn" onClick={handleAddToCart}>
                    Add To Cart
                  </button>
                </div>
                <div className="ra-cart-text">
                  <div onClick={handleAddToFavourite}>
                    <i className="fa-solid fa-heart"><h4>Add to Favourite</h4></i>
                  </div>
                  <i className="fa-solid fa-share"><h4>Share</h4></i>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="related-images">
          <div>
            <h2>Related Images</h2>
          </div>
          <Slider {...settings}>
            {relatedImage.map((img) => (
              <div key={img.id} className="related-image">
                <img 
                  src={`${imgPath}/${img.image}`} 
                  alt={`Related Image ${img.id}`} 
                  onClick={() => handleRelatedImageClick(img.id)}
                />
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default MainImage;
