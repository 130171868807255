import React, { useState } from "react";

import "./galary.css";

function Contact() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // handle form submission
    console.log(formData);
  };
  return (
    <div>
      <div className="ra-main-contact">
        <div className="row">
          <div className="col-md-5">
            <div className="ra-contact-img">
              <img
                src="img/contactimg.jpg"
                alt=""
                srcset=""
                className="img-fluid"
              />
            </div>
          </div>
          <div className="col-md-7">
            <div className="container">
              <div className="ra-contact-main-form">
                <div className="ra-contact-text">
                  <h1>Get in Touch with Photography</h1>
                  <p>
                    At Photography, we're here to help you with all your
                    photography needs. Whether you have questions, need more
                    information, or are ready to book a session, our team is
                    ready to assist you. Please reach out to us using the
                    contact details below.
                  </p>
                </div>

                <form className="ra-contact-form" onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="tm-contact-form-panel">
                        <label htmlFor="name">Name</label>
                        <div className="input-contact-icon-wrapper">
                          <i class="fa-solid fa-user"></i>
                          <input
                            type="text"
                            id="name"
                            name="name"
                            placeholder="First Name"
                            value={formData.name}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="tm-contact-form-panel">
                        <label htmlFor="name">Name</label>
                        <div className="input-contact-icon-wrapper">
                          <i class="fa-solid fa-user"></i>
                          <input
                            type="text"
                            id="name"
                            name="name"
                            placeholder="Last Name"
                            value={formData.name}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="tm-contact-form-panel">
                        <label htmlFor="phone">Mobile</label>
                        <div className="input-contact-icon-wrapper">
                          <i className="fa fa-phone"></i>
                          <input
                            type="tel"
                            id="phone"
                            name="phone"
                            placeholder="Your Phone Number"
                            value={formData.phone}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="tm-contact-form-panel">
                        <label htmlFor="email">Email</label>
                        <div className="input-contact-icon-wrapper">
                          <i className="fa fa-envelope"></i>
                          <input
                            type="email"
                            id="email"
                            name="email"
                            placeholder="Your Email"
                            value={formData.email}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="tm-contact-form-panel">
                        <label htmlFor="message">Message</label>
                        <div className="input-contact-icon-wrapper">
                          <textarea
                            name="message"
                            placeholder="Your Message"
                            value={formData.message}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="ra-contact-btn">
                        <input type="submit" value="CONTACT" />
                      </div>
                    </div>



                    <div className="ra-contact-icon-info">
                 
                      <div className="ra-location">
                      <i class="fa-solid fa-location-dot">
                          <h5>Lorem Ipsum is simply dummy</h5>
                        </i>
                      </div>
                    
                 
                  
                    <div className="ra-phone">
                    <i class="fa-solid fa-phone">
                        <h5>123-456-789</h5>
                      </i>
                    </div>
                 
                    <div className="ra-mail">
                    <i class="fa-solid fa-envelope">
                        <h5>info@gmail.com</h5>
                      </i>
                    </div>
                  
              </div>
                  </div>
                </form>

                
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
