import { useState, useEffect } from "react";
import "./galary.css";

function Home() {


  const [homebanner, setHomebanner] = useState([]);
  const [imgpath, setImgpath] = useState("");

  useEffect(() => {
    fetch("https://photography.ehtwebaid.com/backend/public/api/homebanner")
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (data.status) {
          setHomebanner(data.data);
          setImgpath(data.path);
        } else {
          console.log("No data or empty array received from the API.");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);


    const [isOpen, setIsOpen] = useState(false);
      
    const openNav = () => {
      setIsOpen(true);
    };
  
    const closeNav = () => {
      setIsOpen(false);
    };


  return (
    <div>

<div className="ra-home-navbar">
      <div className="ra-home-logo">
        <a href="/"><img src="img/Logo white.png" alt="" srcset="" className="img-fluid" /></a>
      </div>
      

      <div>
      <div id="myNav" className={`ra-home-overlay ${isOpen ? 'open' : ''}`}>
        <a href="javascript:void(0)" className="closebtn" onClick={closeNav}>&times;</a>
        <div className="ra-home-overlay-content">
          <a href="/">Home</a>
          <a href="/About">About Me</a>
          <a href="/Galary">Categories</a>
          <a href="/BookNow">Book Me</a>
          <a href="/SocialMedia">Social Media</a>
          <a href="#">Contact</a>
        </div>
      </div>
      <span style={{ fontSize: "30px", cursor: "pointer", color:"#fff" }} onClick={openNav}>&#9776;</span>
    </div>
      </div>

      <section className="ra-home-banner">
        <img src={`${imgpath}/${homebanner.homebanner}`} alt="" />

        <div className="overlay">
          <div className="container">
            <div className="ra-home-text">
              <div className="ra-home-text-box">
                <h2>{homebanner.title}</h2>
                <p>
                  {homebanner.description}
                </p>
                <div className="ra-home-btn">
                <div className="ra-homeabout-btn">
                  <a href="/BookNow" className="default-btn">
                    Book Now
                  </a>
                </div>

                <div className="ra-about-btn">
                  <a href="/Galary" className="default-btn">
                    Shop now
                  </a>
                </div>
                </div>
                <div className="ra-explore">
                <a href="/Galary"><h4>I just want to explore</h4></a>
                  </div>  
               
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Home;
