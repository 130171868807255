import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { logout } from "../src/store/userSlice";
import { Link, useNavigate } from "react-router-dom";
import "./navber&footer.css"; // Make sure to create this CSS file
import "@fortawesome/fontawesome-free/css/all.min.css";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const openNav = () => setIsOpen(true);
  const closeNav = () => setIsOpen(false);
  const toggleDropdown = () => setDropdownOpen((prev) => !prev);

  const handleLogout = () => {
    localStorage.removeItem("user");
    dispatch(logout());
    navigate("/Loginsignup"); // Redirect after logout
  }

  const [searchType, setSearchType] = useState("all");
  const [searchText, setSearchText] = useState("");

  const handleSearch = (event) => {
    event.preventDefault();
    if (searchText.trim() === "") {
      return; // Do nothing if the search text is empty
    }
    navigate("/Searchresult", { state: { searchType, searchText } });
  };

  let user = JSON.parse(localStorage.getItem("user"));

  return (
    <div className="navbar">
      <div className="logo">
        <a href="/">
          <img src="/img/LogoBlack.png" alt="Logo" />
        </a>
      </div>
      <div className="ra-search-box">
        <form onSubmit={handleSearch}>
          <select
            value={searchType}
            onChange={(e) => setSearchType(e.target.value)}
          >
            <option value="all">All</option>
            <option value="Portrait">Portrait</option>
            <option value="Landscape">Landscape</option>
          </select>
          <input
            type="text"
            placeholder="Search with image name, type, category"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
          <button type="submit">
            <i className="fa fa-search"></i>
          </button>
        </form>
      </div>
      <div className="ra-main-nav-btn">
        <div className="ra-nav-btn">
          <a href="/BookNow" className="default-btn">Book now</a>
        </div>
        {user ? (
          <div className="ra-nav-loginbtn" onClick={toggleDropdown}>
            {`${user.name}`}
            <div className={`dropdown-menu ${dropdownOpen ? "show" : ""}`}>
            <div className="ra-fev">  
              <Link to="/" onClick={handleLogout}><i class="fa-solid fa-right-from-bracket"><h4>Logout</h4></i></Link>
              
              <Link to="/AddTofev" ><i className="fa-solid fa-heart"><h4>Add to Favourite</h4></i></Link>
              <Link to="/OrderHistory" ><i class="fa-solid fa-bag-shopping"><h4>Order History</h4></i></Link>
              </div>
              
            </div>
          </div>
        ) : (
          <a href="/Loginsignup" className="ra-nav-loginbtn">Log In</a>
        )}
        <div className="ra-nav-cart">
          <a href="/AddTocart">
            <i className="fa-solid fa-cart-shopping"></i>
          </a>
        </div>
      </div>

      <div>
        <div id="myNav" className={`ra-overlay ${isOpen ? "open" : ""}`}>
          <a href="javascript:void(0)" className="closebtn" onClick={closeNav}>&times;</a>
          <div className="ra-overlay-content">
            <a href="/">Home</a>
            <a href="/About">About Me</a>
            <a href="/Galary">Categories</a>
            <a href="/BookNow">Book Me</a>
            <a href="/SocialMedia">Social Media</a>
            <a href="/Contact">Contact</a>
          </div>
        </div>
        <span style={{ fontSize: "30px", cursor: "pointer" }} onClick={openNav}>&#9776;</span>
      </div>
    </div>
  );
};

export default Navbar;
