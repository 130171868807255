import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { payments } from '@square/web-sdk';
import Swal from 'sweetalert2';
import './addTocart.css';
const imgPath = 'https://photography.ehtwebaid.com/backend/public/uploads/categoryimage/';

const PaymentForm = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { cartItems, total } = location.state || { cartItems: [], total: 0 };
  let user = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    const initializePaymentForm = async () => {
      try {
        const paymentsInstance = await payments('sq0idp-D2LncsL6WlbN150e4shHIg', 'production'); // Use 'production' for live transactions
        const card = await paymentsInstance.card();
        await card.attach('#card-container');

        document.getElementById('card-button').addEventListener('click', async (event) => {
          event.preventDefault();
          
          // Show processing state
          Swal.fire({
            title: 'Processing...',
            text: 'Please wait while we process your payment.',
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            }
          });

          const result = await card.tokenize();
          if (result.status === 'OK') {
            const response = await fetch('https://photography.ehtwebaid.com/backend/public/api/square-payment', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                nonce: result.token,
                amount: total // Amount in cents
              })
            });

            const data = await response.json();
            if (data.error) {
              Swal.fire({
                icon: 'error',
                title: 'Payment Failed',
                text: 'There was a problem processing your payment. Please try again.',
                confirmButtonText: 'OK'
              });
            } else {
              // Construct orderlist array
              const orderlist = cartItems.map(item => ({
                userid: user.userid,
                imageid: item.categoryimage_id,
                priceid: item.pricelist_id,
                quantity: item.quantity,
                totalprice: (item.totalPrice * item.quantity).toFixed(2) // Ensure totalprice is calculated
              }));

              console.log(orderlist);

              // Call order history API and remove cart items
              await fetch('https://photography.ehtwebaid.com/backend/public/api/orderHistory', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({ orderlist }),
              });

              Swal.fire({
                icon: 'success',
                title: 'Payment Successful',
                text: 'Your payment was processed successfully!',
                confirmButtonText: 'OK'
              }).then(() => {
                navigate('/OrderHistory'); // Redirect to order history page
              });
            }
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Tokenization Failed',
              text: 'There was a problem with tokenizing your payment details. Please try again.',
              confirmButtonText: 'OK'
            });
          }
        });
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Initialization Error',
          text: 'There was an error initializing the payment form. Please refresh the page and try again.',
          confirmButtonText: 'OK'
        });
      }
    };

    initializePaymentForm();
  }, [total, navigate, user.userid, cartItems]);

  return (
    <div className="container">
      <div className="ra-main-card">
        <div className="row">
          <div className="ra-card-order col-md-8">
            <h2>Your Order</h2>
            {cartItems.map((item, index) => (
              <div className="product" key={index}>
                <div className="product-image">
                  <img src={`${imgPath}${item.image}`} alt={item.categoryname} />
                </div>
                <div className="product-details">
                  <p className="product-description">{item.categoryname}</p>
                </div>

                <div className="product-quantity">
                  Quantity: {item.quantity}
                </div>
                <div className="product-price">
                  Price: ${(item.totalPrice * item.quantity).toFixed(2)} {/* Calculate total price */}
                </div>
              </div>
            ))}
          </div>

          <div className="ra-card col-md-4">
            <h2>Payment</h2>
            <div id="card-container"></div>
            <button id="card-button">${total.toFixed(2)}</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentForm;
