import React, { useState, useEffect } from "react";
import "./addTocart.css";

const AddTofev = () => {
  const [favouriteItems, setFavouriteItems] = useState([]);
  const [imgPath, setImgPath] = useState("");

  useEffect(() => {
    fetchfavouriteItems();
  }, []);

  let user = JSON.parse(localStorage.getItem("user"));

  const fetchfavouriteItems = async () => {
    try {
      const apiUrl =
        "https://photography.ehtwebaid.com/backend/public/api/fetchfavourite";
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ customerid: user.userid }),
      });

      const data = await response.json();
      console.log(data); // Log the response for debugging

      if (data.status) {
        setFavouriteItems(data.data); // Update with the correct data structure
        setImgPath(data.path);
      } else {
        console.log("No data or empty array received from the API.");
      }
    } catch (error) {
      console.error("Error fetching favourite items:", error);
    }
  };

  const removeItem = async (id) => {
    try {
      const apiUrl = `https://photography.ehtwebaid.com/backend/public/api/removefavourite`;
      const response = await fetch(apiUrl, {
        method: "POST", // Ensure the correct HTTP method
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ favouriteId: id, userid: user.userid }),
      });

      const data = await response.json();
      console.log(data); // Log the response for debugging

      if (data.status) {
        setFavouriteItems((prevItems) => prevItems.filter((item) => item.id !== id)); // Update the favourite items state
      } else {
        console.error("Error removing item from favourites:", data.message);
      }
    } catch (error) {
      console.error("Error removing item from favourites:", error);
    }
  };

  return (
    <div className="container">
      <div className="wishlist">
        <h1>Add To Favourite</h1>
        {favouriteItems.length > 0 ? (
          favouriteItems.map((item) => (
            <div key={item.id} className="wishlist-item">
              <img src={`${imgPath}/${item.image}`} alt={item.categoryname} />
              <div className="item-details">
                <h2>{item.categoryname}</h2>
                <div className="item-price">
                  <span>${item.priceDetails}</span>
                </div>
              </div>
              <button className="delete-button" onClick={() => removeItem(item.id)}>🗑️</button>
            </div>
          ))
        ) : (
          <p>No items in your favourite list.</p>
        )}
      </div>
    </div>
  );
};

export default AddTofev;
