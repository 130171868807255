import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loginUser, registerUser } from "../store/userSlice";
import { useNavigate } from "react-router-dom";
import "./Loginsignup.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

const Loginsignup = () => {
  const [isLogin, setIsLogin] = useState(true);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [errors, setErrors] = useState({});
  const [loginSuccessMessage, setLoginSuccessMessage] = useState("");
  const [signupSuccessMessage, setSignupSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.user);

  const handleLoginToggle = () => {
    setIsLogin(true);
    clearMessages();
  };

  const handleSignupToggle = () => {
    setIsLogin(false);
    clearMessages();
  };

  const handlePasswordToggle = () => {
    setShowPassword(!showPassword);
  };

  const handleConfirmPasswordToggle = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const validateEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  const clearMessages = () => {
    setLoginSuccessMessage("");
    setSignupSuccessMessage("");
    setErrorMessage("");
  };

  useEffect(() => {
    if (user) {
      setLoginSuccessMessage("Login successful!");
      setErrorMessage("");
      setTimeout(() => {
        navigate("/Galary");
      }, 2000);
    }
  }, [user, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = {};

    if (!validateEmail(email)) {
      errors.email = "Please enter a valid email address";
    }

    if (!isLogin && password !== confirmPassword) {
      errors.password = "Passwords do not match";
    }

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }

    if (isLogin) {
      try {
        await dispatch(loginUser({ email, password })).unwrap();
        setLoginSuccessMessage("Login successful....!");
        setErrorMessage("");
      } catch (error) {
        setErrorMessage("Login failed. Please check your credentials.");
      }
    } else {
      try {
        await dispatch(registerUser({ name, email, password })).unwrap();
        setSignupSuccessMessage("Signup successful....!");
        setErrorMessage("");
        // Clear the registration fields
        setName("");
        setEmail("");
        setPassword("");
        setConfirmPassword("");
        setTimeout(() => {
          navigate("/Loginsignup");
        }, 2000);
      } catch (error) {
        setErrorMessage("Signup failed. Please try again.");
      }
    }
  };

  return (
    <div
      className="main-login-signup-img"
      style={{
        backgroundImage: "url(/img/login.png)",
        objectFit: "cover",
        width: "100%",
        height: "90vh",
        backgroundSize: "cover",
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="ra-loginsignup">
        <div className="wrapper">
          <div className="slide-controls">
            <input
              type="radio"
              name="slide"
              id="login"
              checked={isLogin}
              onChange={handleLoginToggle}
            />
            <input
              type="radio"
              name="slide"
              id="signup"
              checked={!isLogin}
              onChange={handleSignupToggle}
            />
            <label
              htmlFor="login"
              className="slide login"
              onClick={handleLoginToggle}
            >
              Login
            </label>
            <label
              htmlFor="signup"
              className="slide signup"
              onClick={handleSignupToggle}
            >
              REGISTER
            </label>
            <div className="slider-tab"></div>
          </div>
          <div className="form-container">
            <div className="form-inner">
              <form
                onSubmit={handleSubmit}
                className={isLogin ? "login" : "signup"}
              >
                {!isLogin && (
                  <div className="field">
                    <label htmlFor="name">Name</label>
                    <div className="input-login-icon-wrapper">
                      <i className="fa-solid fa-user"></i>
                      <input
                        type="text"
                        id="name"
                        placeholder="User Name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                )}
                <div className="field">
                  <label htmlFor="email">Email</label>
                  <div className="input-login-icon-wrapper">
                    <i className="fa-solid fa-envelope"></i>
                    <input
                      type="email"
                      id="email"
                      placeholder="Your Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                    {errors.email && <p className="error">{errors.email}</p>}
                  </div>
                </div>
                <div className="field">
                  <label htmlFor="password">Password</label>
                  <div className="input-sign-icon-wrapper">
                    <input
                      type={showPassword ? "text" : "password"}
                      id="password"
                      placeholder="Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                    />
                    <i
                      className={`fa-solid ${
                        showPassword ? "fa-eye" : "fa-eye-slash"
                      }`}
                      onClick={handlePasswordToggle}
                    ></i>
                  </div>
                </div>
                {!isLogin && (
                  <div className="field">
                    <label htmlFor="confirmPassword">Confirm Password</label>
                    <div className="input-sign-icon-wrapper">
                      <input
                        type={showConfirmPassword ? "text" : "password"}
                        id="confirmPassword"
                        placeholder="Confirm Password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        required
                      />
                      <i
                        className={`fa-solid ${
                          showConfirmPassword ? "fa-eye" : "fa-eye-slash"
                        }`}
                        onClick={handleConfirmPasswordToggle}
                      ></i>
                    </div>
                    {errors.password && (
                      <p className="error">{errors.password}</p>
                    )}
                  </div>
                )}
                {isLogin && (
                  <div className="pass-link">
                    <a href="#" onClick={() => navigate("/resetpassword")}>
                      Forgot password?
                    </a>
                  </div>
                )}
                {isLogin && loginSuccessMessage && (
                  <p className="success">{loginSuccessMessage}</p>
                )}
                {!isLogin && signupSuccessMessage && (
                  <p className="success">{signupSuccessMessage}</p>
                )}
                {errorMessage && <p className="error">{errorMessage}</p>}
                <div className="ra-login-btn">
                  <input type="submit" value={isLogin ? "Login" : "Signup"} />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Loginsignup;
