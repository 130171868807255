import React, { useState, useEffect } from "react";
import "./addTocart.css"; // Import the CSS file

const OrderHistory = () => {
  const [orders, setOrders] = useState([]);
  const [imgPath, setImgPath] = useState("");

  let user = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    const apiUrl = "https://photography.ehtwebaid.com/backend/public/api/fetchorderHistory";

    fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ customerid: user.userid }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status) {
          setOrders(data.data);
          setImgPath(data.path);
        } else {
          console.log("No data or empty array received from the API.");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div className="order-history">
      <h2>Order History</h2>
      <div className="table-responsive">
        <table>
          <thead>
            <tr>
              <th>Image</th>
              <th>Order Date</th>
              <th>Order Number</th>
              <th>Category Name</th>
              <th>Image Type</th>
              <th>Total</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {orders.map((order, index) => (
              <tr key={index}>
                <td><img src={`${imgPath}/${order.image}`} alt="" /></td>
                <td>{order.created_at.slice(0, 10)}</td>
                <td>{order.orderId}</td>
                <td>{order.categoryname}(Qty:{order.quantity})</td>
                <td>{order.imagetype}</td>
                <td>${order.totalprice}</td>
                <td><h3>success</h3></td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default OrderHistory;
