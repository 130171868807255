import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
import "./addTocart.css";

const AddToCart = () => {
  const [cartItems, setCartItems] = useState([]);
  const [imgPath, setImgPath] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    fetchCartItems();
  }, []);

  let user = JSON.parse(localStorage.getItem("user"));

  const fetchCartItems = async () => {
    try {
      const apiUrl =
        "https://photography.ehtwebaid.com/backend/public/api/fetchcart";
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ customerid: user.userid }),
      });

      const data = await response.json();
      console.log(data); // Log the response for debugging

      if (data.status) {
        setCartItems(data.data); // Update with the correct data structure
        setImgPath(data.path);
      } else {
        console.log("No data or empty array received from the API.");
      }
    } catch (error) {
      console.error("Error fetching cart items:", error);
    }
  };

  const removeItem = async (id) => {
    try {
      const apiUrl = `https://photography.ehtwebaid.com/backend/public/api/removeCart`;
      const response = await fetch(apiUrl, {
        method: "POST", // Ensure the correct HTTP method
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ cartid: id, userid: user.userid }),
      });

      const data = await response.json();
      console.log(data); // Log the response for debugging

      if (data.status) {
        setCartItems((prevItems) => prevItems.filter((item) => item.id !== id)); // Update the cart items state
      } else {
        console.error("Error removing item from cart:", data.message);
      }
    } catch (error) {
      console.error("Error removing item from cart:", error);
    }
  };

  const updateQuantity = async (id, quantity, price) => {
    if (quantity < 1) {
      return;
    }
    // let obj={cartid:"1",quantity: "2",totalprice: "10" };
    try {
      const apiUrl =
        "https://photography.ehtwebaid.com/backend/public/api/updateQuantity";
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          cartid: id,
          quantity: +quantity + 1,
          totalprice: price,
        }),
      });

      const data = await response.json();
      console.log(data); // Log the response for debugging

      if (data.status) {
        fetchCartItems();
        console.log(data.data);
      } else {
        console.log("No data or empty array received from the API.");
      }
    } catch (error) {
      console.error("Error fetching cart items:", error);
    }
  };

  console.log("fetchCartItems");

  const recalculateCart = () => {
    const subtotal = cartItems.reduce(
      (acc, item) =>
        acc + parseFloat(item.totalPrice) * parseInt(item.quantity),
      0
    );
    const tax = subtotal * 0.05; // Assuming 5% tax rate
    const shipping = subtotal > 0 ? 5.0 : 0; // Assuming a flat shipping rate
    const total = subtotal + tax + shipping;

    return { subtotal, tax, shipping, total };
  };

  const { subtotal, tax, shipping, total } = recalculateCart();

  const handleCheckoutClick = () => {
    if (cartItems.length === 0) {
      Swal.fire({
        icon: 'info',
        title: 'Your cart is empty',
        text: 'Please add items to your cart before proceeding to checkout.',
      });
      return;
    }

    const user = JSON.parse(localStorage.getItem("user"));
    if (!user) {
      navigate("/login");
      return;
    }
    navigate("/PaymentForm", { state: { cartItems, total } });
  };

  return (
    <div className="shopping-cart">
      <div className="container">
        <div className="row">
          <div className="col-md-8">
            <div className="row">
              <div className="column-labels">
                <label className="product-image">Image</label>
                <label className="product-details">Product</label>
                <label className="product-price">Price</label>
                <label className="product-quantity">Quantity</label>
                <label className="product-line-price">Total</label>
              </div>
            </div>

            {cartItems.length > 0 ? (
              cartItems.map((item) => (
                <div className="product" key={item.id}>
                  <div className="product-image">
                    <img
                      src={`${imgPath}/${item.image}`}
                      alt={item.categoryname}
                    />
                  </div>
                  <div className="product-details">
                    <p className="product-description">{item.categoryname}</p>
                  </div>
                  <div className="product-price">
                    {parseFloat(item.totalPrice).toFixed(2)}
                  </div>
                  <div className="product-quantity">
                    <input
                      type="number"
                      value={item.quantity}
                      min="1"
                      onClick={() =>
                        updateQuantity(item.id, item.quantity, item.totalPrice)
                      }
                    />
                  </div>
                  <div className="product-line-price">
                 {(
                      parseFloat(item.totalPrice) * parseInt(item.quantity)
                    ).toFixed(2)}
                  </div>
                  <button
                    className="remove-product"
                    onClick={() => removeItem(item.id)}
                  >
                    Remove
                  </button>
                </div>
              ))
            ) : (
              <p>Your cart is empty.</p>
            )}
          </div>
          <div className="col-md-4">
            <div className="ra-main-total">
              <div className="totals">
                <div className="totals-item">
                  <label>Subtotal</label>
                  <div className="totals-value" id="cart-subtotal">
                    {subtotal.toFixed(2)}
                  </div>
                </div>
                <div className="totals-item">
                  <label>Tax (5%)</label>
                  <div className="totals-value" id="cart-tax">
                    {tax.toFixed(2)}
                  </div>
                </div>
                <div className="totals-item">
                  <label>Shipping</label>
                  <div className="totals-value" id="cart-shipping">
                    {shipping.toFixed(2)}
                  </div>
                </div>
                <div className="totals-item totals-item-total">
                  <label>Grand Total</label>
                  <div className="totals-value" id="cart-total">
                    {total.toFixed(2)}
                  </div>
                </div>
              </div>
              <div className="ra-check-btn">
                <button className="checkout" onClick={handleCheckoutClick}>
                  Checkout
                </button>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddToCart;
