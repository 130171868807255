import React, { useState } from "react";
import '@fortawesome/fontawesome-free/css/all.min.css';

import "./galary.css";

function BookNow() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    purpose: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // handle form submission
    console.log(formData);
  };

  return (
    <div>
      <section className="rw-home-banner">
        <img src="img/booknow.jpg" alt="" />

        <div className="overlay">
          <div className="container">
            <div className="rw-home-text">
              <form className="rw-form" onSubmit={handleSubmit}>
                <div className="rw-camara-img">
                  <img src="img/camera.png" alt="" srcset=""  className="img-fluid"/>
                </div>
              <div className="rw-logo-from">
                <img src="img/LogoBlack.png" alt="" srcset="" className="img-fluid"/>
              </div>
                <h2>Your Studio Photography</h2>

                <label htmlFor="name">
                   Name
                </label>
                <div className="input-icon-wrapper">
                <i class="fa-solid fa-user"></i>
                <input
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Your Name"
                  value={formData.name}
                  onChange={handleChange}
                />
</div>
                <label htmlFor="email">
                   Email
                </label>

                <div className="input-icon-wrapper">
                <i className="fa fa-envelope"></i>
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Your Email"
                  value={formData.email}
                  onChange={handleChange}
                />
                </div>

                <label htmlFor="phone">
                   Mobile
                </label>

                <div className="input-icon-wrapper">
                <i className="fa fa-phone"></i>
                <input
                  type="tel"
                  id="phone"
                  name="phone"
                  placeholder="Your Phone Number"
                  value={formData.phone}
                  onChange={handleChange}
                />
                </div>

                <label htmlFor="purpose">
                  Purpose
                </label>

                
                <select
                  id="purpose"
                  name="purpose"
                  value={formData.purpose}
                  onChange={handleChange}
                >
                  <option value="">-- Select --</option>
                  <option value="wedding">Wedding</option>
                  <option value="portrait">Portrait</option>
                  <option value="event">Event</option>
                </select>
                <div className="rw-btn">
                <input type="submit" value="Book Now" />
                </div>
               
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default BookNow;
