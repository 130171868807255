import React from "react";

function Footer() {
  return (
    <div>
      <div className="main-Footer">
        <div className="ra-Footer-icon">
          <i class="fa-brands fa-facebook"></i>
          <i class="fa-brands fa-instagram"></i>
          <i class="fa-brands fa-linkedin-in"></i>
          <i class="fa-brands fa-x-twitter"></i>
        </div>
        <div className="ra-Footer-weblinkl">
          <a href="">
            <h2>www.photographerwebsite.com</h2>
          </a>
        </div>
        <div className="ra-Footer-menu">
          <a href="/">Home</a>
          <a href="/About">About Me</a>
          <a href="/Galary">Categories</a>
          <a href="/BookNow">Book Me</a>
          <a href="/SocialMedia">Social Media</a>
          <a href="/Contact">Contact</a>
        </div>

        <div className="ra-Footer-company">
          <p>
            © 2024 Copyrights. All rights reserved by photographerwebsite.com.
            Design & Developed by <a href="https://ehtwebaid.com/">EHT Webaid Pvt Ltd.</a>{" "}
          </p>
        </div>
      </div>
    </div>
  );
}

export default Footer;
